<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title title-tag="h5">Revenue</b-card-title>
        <b-button @click="addRevenue" class="btn-rounded" variant="info">Add Revenue <i class="fa fa-plus ml-2"></i></b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">              
        <template v-if="showContent">
          <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-revenue', apiGet)" id="revenue" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
        </template>
        <b-button 
          variant="secondary"
          class="btn-rounded ml-auto"
          v-if="formOpen"
          @click="closeRevenueForm">
          Close <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button
          variant="outline-info"
          class="btn-rounded ml-auto"
          @click="showRevenueUpdate"
          v-else
        >
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>

      <hr />

      <validation-observer
        v-slot="{ handleSubmit }"
        ref="VFormRevenueDesc"
        tag="div"
        v-if="formOpen"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitDesc)">
          <b-card class="border mb-0">
            <template #header>
              <h5 class="card-title">Content</h5>
            </template>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_id"  />
                  <VValidate 
                    name="Title (ID)" 
                    v-model="dataModal.asc_title_id"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_en"  />
                  <VValidate 
                    name="Title (EN)" 
                    v-model="dataModal.asc_title_en"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_jp"  />
                  <VValidate 
                    name="Title (JP)" 
                    v-model="dataModal.asc_title_jp"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (ID)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_id"  />
                  <VValidate 
                    name="Description (ID)" 
                    v-model="dataModal.asc_content_id"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (EN)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_en"  />
                  <VValidate 
                    name="Description (EN)" 
                    v-model="dataModal.asc_content_en"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (JP)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_jp"  />
                  <VValidate 
                    name="Description (JP)" 
                    v-model="dataModal.asc_content_jp"
                    rules="min:3" 
                  />
                </div>
              </b-col>
            </b-row>
            <template #footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-3" @click="closeRevenueForm">Cancel</b-button>
                <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
              </div>
            </template>
          </b-card>
        </b-form>
      </validation-observer>
      <div class="row justify-content-center" v-else>
        <div class="col-md-8 text-center">
          <h3>{{dataModal.asc_title_id}}</h3>
          <div class="sect-heading__line"></div>
          <p>{{dataModal.asc_content_id}}</p>
        </div>
      </div>
      
      <b-table v-if="!formOpen" :items="revenue" :fields="fields" striped responsive show-empty>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(num)="v">
          {{ v.index + 1 }}
        </template>
        <template #cell(mr_revenue)="data">
          {{data.value}} Billion
        </template>
        <template #cell(mr_background_color)="data">
          <div class="d-flex align-items-center">
            {{data.value}} 
            <div :style="`margin-left: 10px; width: 20px; border-radius: 4px;   height: 20px; background-color:${data.value}`"></div>
          </div>
        </template>
        <template #cell(mr_is_active)="data">
          <b-badge v-if="data.value == 'Y'" variant="success">Active</b-badge>
          <b-badge v-else variant="danger">Inactive</b-badge>
        </template>
        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover
            title="Edit"
            class="btn-icon"
            variant="outline-primary"
            @click="updateRevenue(data.item)"
            size="sm"
          >
            <i class="ti-marker-alt"></i>
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Delete"
            @click="$emit('delete', data.item, {type: 'deleteRevenue', obj: 'Revenue', idKey: 'mr_id'}, apiGet)"
            class="btn-icon"
            variant="outline-danger"
            size="sm"
          >
            <i class="ti-trash"></i>
          </b-button>
        </template>
      </b-table>

      <b-modal id="modalRevenue" title="Form Revenue" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormRevenue"
        >
          <b-form @submit.prevent="handleSubmit(doSubmit)">
            <b-card-body>
              <b-row>
                <b-col md=6>
                  <div class="form-group">
                    <label>Year <strong class="text-danger">*</strong></label>
                    <b-form-input v-model="row.mr_year" :formatter="e => String(e).replace(/[^0-9]+/g, '').substr(0,4)"></b-form-input>
                    <VValidate 
                      name="Year" 
                      v-model="row.mr_year"
                      :rules="validation.mr_year" 
                    />
                  </div>
                </b-col>
                <b-col md=6>
                  <div class="form-group">
                    <label>Revenue <strong class="text-danger">*</strong></label>
                    <b-input-group>
                      <template #append>
                        <b-input-group-text>Billion</b-input-group-text>
                      </template>
                      <b-form-input type="number" v-model.number="row.mr_revenue" step="any"/>
                    </b-input-group>
                    <VValidate 
                      name="Revenue" 
                      v-model="row.mr_revenue"
                      :rules="validation.mr_revenue" 
                    />
                  </div>
                </b-col>
                <b-col md=4>
                  <div class="form-group">
                    <label class="control-label">Background Color <span class="text-danger mr5">*</span></label>
                    <verte :key="randInt" v-if="randInt" v-model="row.mr_background_color" model="hex" :enableAlpha="false"></verte>
                    <VValidate name="Background Color" v-model="row.mr_background_color" :rules="validation.mr_background_color" />
                  </div>
                </b-col>
                <b-col md=8>
                  <div class="form-group">
                    <label class="control-label">Status <span class="text-danger mr5">*</span></label>
                    <b-form-radio-group
                      :options="parent.Config.mr.StatusOptions"
                      v-model="row.mr_is_active"
                    />
                    <VValidate 
                      name="Status" 
                      v-model="row.mr_is_active" 
                      :rules="validation.mr_is_active" 
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="doSubmit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
    </b-card-body>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
let _ = global._

export default {
  props: {
    modulePage: String,
  },
  data(){
    return {
      validation: {},
      formOpen: false,
      revenue: [],
      fields: [
        {key: 'num', label: '#'},
        {key: 'mr_year', label: 'Year'},
        {key: 'mr_revenue', label: 'Revenue'},
        {key: 'mr_background_color', label: 'Color'},
        {key: 'mr_is_active', label: 'Status'},
        {key: 'action', label: "Action"}
      ],
      row: {},
      showContent: null,
      randInt: 0,
      dataModal: {}
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
    this.$root.$on('bv::modal::hidden', () => {
      this.randInt = 0
    })
    this.$root.$on('bv::modal::shown', () => {
      this.randInt = 1
    })
  },
  methods: {
    showRevenueUpdate(){
      this.formOpen = true
    },
    closeRevenueForm(){
      this.formOpen = false
    },
    addRevenue(){
      this.row = {mr_is_active: 'Y', mr_background_color: '#39768E'}
      this.$bvModal.show('modalRevenue')
    },
    doSubmit(){
      this.$refs.VFormRevenue.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: this.row.mr_id ? 'updateRevenue' : 'addRevenue', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalRevenue')
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    updateRevenue(value){
      this.row = _.clone(value)
      this.$bvModal.show('modalRevenue')
    },
    doSubmitDesc(){
      this.$refs.VFormRevenueDesc.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateRevenueDesc', ...this.dataModal } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.formOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/revenue', {}, 'GET').then(res => {
        console.log(res.data.revenue)
        this.$set(this, 'revenue', res.data.revenue)
        this.$set(this, 'showContent', res.data.showContent)
        this.$set(this, 'validation', res.data.validation)
        this.$set(this, 'dataModal', res.data.description)
      })
    }
  }
}
</script>