<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Our Business</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-our-business', apiGet)" id="business" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="formOpen"
        @click="closeForm">
        Close <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showUpdateForm"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormVisi"
      tag="div"
      v-if="formOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (ID) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_id"></b-form-input>
                <VValidate name="Title (ID)" v-model="row.asc_title_id" :rules="validation.asc_title_id" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (EN) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_en"></b-form-input>
                <VValidate name="Title (EN)" v-model="row.asc_title_en" :rules="validation.asc_title_en" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (JP) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_jp"></b-form-input>
                <VValidate name="Title (JP)" v-model="row.asc_title_jp" :rules="validation.asc_title_jp" />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (ID) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  ref="ourBusinessID"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_id"

                />
                <VValidate 
                  name="Content (ID)" 
                  v-model="row.asc_content_id"
                  :rules="validation.asc_content_id" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (EN) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="ourBusinessEN"
                  ref="inputBriefCompanyEN"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_en"

                />
                <VValidate 
                  name="Content (EN)" 
                  v-model="row.asc_content_en"
                  :rules="validation.asc_content_en" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (JP) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="ourBusinessJP"
                  ref="inputBriefCompanyJP"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_jp"

                />
                <VValidate 
                  name="Content (JP)" 
                  v-model="row.asc_content_jp"
                  :rules="validation.asc_content_jp" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">Image <span class="text-danger">*</span></label>         
                <Uploader v-model="row.asc_image" type="our_business"/>
                <VValidate 
                  name="Image" 
                  v-model="row.asc_image"
                  :rules="validation.asc_image" 
                />
              </div>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closeForm">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row" v-else>
      <div class="col-md-8">
        <div v-html="content.asc_content_id"></div>
      </div>
      <div class="col-md-4">
        <img :src="parent.uploader(content.asc_image)" alt="">
      </div>
    </div>

  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
import CKEditor from '@/components/CKEditor'
let _ = global._

export default {
  components: {
    CKEditor
  },
  props:{
    modulePage: String
  },
  data(){
    return {
      formOpen: false,
      content: {},
      row: {},
      configCKEditor: [ 'heading', '|', 'bold','italic','underline','strikethrough','link','bulletedList','numberedList','blockQuote'],
      validation: {},
      showContent: null,
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    modalVisi(value){
      this.rowAlpha = _.clone(value)
      this.$bvModal.show('modalVisi')
    },
    showUpdateForm(){
      this.formOpen = true
      this.row = _.clone(this.content)
    },
    closeForm(){
      this.formOpen = false
    },
    doSubmit(){
      this.$refs.VFormVisi.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateOurBusiness', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.formOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/our_business', {}, 'GET').then(res => {
        this.content = res.data.row
        this.validation = res.data.validation
        this.showContent = res.data.showContent
      })
    }
  }
}
</script>
