<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Corporate Culture</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-corporate-culture', apiGet)" id="corporate-culture" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="formOpen"
        @click="closeForm">
        Close <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showUpdateForm"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormGlance"
      tag="div"
      v-if="formOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (ID) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_id"></b-form-input>
                <VValidate name="Title (ID)" v-model="row.asc_title_id" :rules="validation.asc_title_id" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (EN) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_en"></b-form-input>
                <VValidate name="Title (EN)" v-model="row.asc_title_en" :rules="validation.asc_title_en" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (JP) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_jp"></b-form-input>
                <VValidate name="Title (JP)" v-model="row.asc_title_jp" :rules="validation.asc_title_jp" />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (ID) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_id"
                  ref="inputBriefCompanyID"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_id"

                />
                <VValidate 
                  name="Content (ID)" 
                  v-model="row.asc_content_id"
                  :rules="validation.asc_content_id" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (EN) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_en"
                  ref="inputBriefCompanyEN"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_en"

                />
                <VValidate 
                  name="Content (EN)" 
                  v-model="row.asc_content_en"
                  :rules="validation.asc_content_en" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (JP) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_jp"
                  ref="inputBriefCompanyJP"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_jp"

                />
                <VValidate 
                  name="Content (JP)" 
                  v-model="row.asc_content_jp"
                  :rules="validation.asc_content_jp" 
                />
              </div>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closeForm">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row" v-else>
      <div class="col-md-12">
        <h3>{{row.asc_title_id}}</h3>
        <div v-html="row.asc_content_id"></div>
      </div>
    </div>
    <div class="form-row justify-content-center mt-3 text-center">
      <div class="col-md-auto" v-for="(value, index) in alphabet" :key="index">
        <b-button
          variant="warning"
          size="sm"
          pill
          @click="modalAlpha(value)"
          class="btn-icon mb-2"
          triggers="hover"
          v-b-tooltip.hover="'Update'"
        >
          <i class="fas fa-pencil-alt" />
        </b-button>
        <div class="wrap_alphabet">
          <div class="initial">
            <h3>{{value.text_init.toUpperCase()}}</h3>
          </div>
          <p>{{ value.text_init }}<span>{{ value.rest_init }}</span></p>
        </div>
      </div>
    </div>
    <b-modal id="modalAlpha" title="Form Alphabet" centered no-close-on-backdrop>
      <validation-observer ref="VFormAlpha" v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(doSubmitAlpha)">
          <b-row>
            <b-col md=6>
              <b-form-group label-for="text">
                <label>Text <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="rowAlpha.text"></b-form-input>
                <VValidate name="Text" v-model="rowAlpha.text" rules="required|min:3" />
              </b-form-group>
            </b-col>
            <b-col md=3>
              <b-form-group label-for="text">
                <label>Initial Length <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="rowAlpha.init" type="number"></b-form-input>
                <VValidate name="Inital Length" v-model="rowAlpha.init" rules="required|min_value:1|max_value:2" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer="{close}">
        <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="doSubmitAlpha" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
import CKEditor from '@/components/CKEditor'
// let _ = global._

export default {
  components: {
    CKEditor
  },
  props:{
    modulePage: String
  },
  data(){
    return {
      formOpen: false,
      row: {},
      rowAlpha: {},
      configCKEditor: [ 'heading', '|', 'bold','italic','underline','strikethrough','link','bulletedList','numberedList','blockQuote'],
      validation: {},
      showCompanyBrief: false,
      showContent: null,
      alphabet: []
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    modalAlpha(value){
      this.rowAlpha = _.clone(value)
      this.$bvModal.show('modalAlpha')
    },
    showUpdateForm(){
      this.formOpen = true
    },
    closeForm(){
      this.formOpen = false
    },
    doSubmit(){
      this.$refs.VFormGlance.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateCorporate', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.formOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    doSubmitAlpha(){
      this.$refs.VFormAlpha.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateAlphabet', ...this.rowAlpha } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('modalAlpha')
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/corporate_culture', {}, 'GET').then(res => {
        this.row = res.data.row
        this.validation = res.data.validation
        this.showContent = res.data.showContent
        this.alphabet = res.data.alphabet
      })
    }
  }
}
</script>
<style>
  .wrap_alphabet {
    position: relative;
  }
  .wrap_alphabet .initial {
    width: 122px;
    background-color: #c01a21;
    border-radius: 100%;
    height: 120px;
    border-radius: 0 0px 20px;
    position: relative;
}
.wrap_alphabet p {
    color: #4d4d4d;
    padding: 10px 0 0 0;
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
}
.wrap_alphabet p span {
    color: #ccc;
}
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.wrap_alphabet .initial h3 {
    color: #fff;
    padding: 5px 0 0 0;
    text-align: center;
    margin: 0;
    font-size: 80px;
}
</style>
