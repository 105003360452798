<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title title-tag="h5">Customers</b-card-title>
        <b-button :to="{name: 'BoClients'}" class="btn-rounded" variant="info">Customer List <i class="fa fa-arrow-right ml-2"></i></b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">              
        <template v-if="showContent">
          <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-about-customer', apiGet)" id="customer" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
        </template>
        <b-button 
          variant="secondary"
          class="btn-rounded ml-auto"
          v-if="productCatOpen"
          @click="closeProductForm">
          Close <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button
          variant="outline-info"
          class="btn-rounded ml-auto"
          @click="showProductUpdate"
          v-else
        >
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>

      <hr />

      <validation-observer
        v-slot="{ handleSubmit }"
        ref="VFormMedia"
        tag="div"
        v-if="productCatOpen"
      >
        <b-form @submit.prevent="handleSubmit(doSubmit)">
          <b-card class="border mb-0">
            <template #header>
              <h5 class="card-title">Content</h5>
            </template>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_id"  />
                  <VValidate 
                    name="Title (ID)" 
                    v-model="row.asc_title_id"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_en"  />
                  <VValidate 
                    name="Title (EN)" 
                    v-model="row.asc_title_en"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="row.asc_title_jp"  />
                  <VValidate 
                    name="Title (JP)" 
                    v-model="row.asc_title_jp"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (ID)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_id"  />
                  <VValidate 
                    name="Description (ID)" 
                    v-model="row.asc_content_id"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (EN)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_en"  />
                  <VValidate 
                    name="Description (EN)" 
                    v-model="row.asc_content_en"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (JP)</label>         
                  <b-form-textarea :rows="3" v-model="row.asc_content_jp"  />
                  <VValidate 
                    name="Description (JP)" 
                    v-model="row.asc_content_jp"
                    rules="min:3" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Bottom Description (ID)</label>         
                  <b-form-textarea :rows="3" v-model="row.bottom_desc_id"  />
                  <VValidate 
                    name="Bottom Description (ID)" 
                    v-model="row.bottom_desc_id"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Bottom Description (EN)</label>         
                  <b-form-textarea :rows="3" v-model="row.bottom_desc_en"  />
                  <VValidate 
                    name="Bottom Description (EN)" 
                    v-model="row.bottom_desc_en"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Bottom Description (JP)</label>         
                  <b-form-textarea :rows="3" v-model="row.bottom_desc_jp"  />
                  <VValidate 
                    name="Bottom Description (JP)" 
                    v-model="row.bottom_desc_jp"
                    rules="min:3" 
                  />
                </div>
              </b-col>
            </b-row>
            <template #footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-3" @click="closeProductForm">Cancel</b-button>
                <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
              </div>
            </template>
          </b-card>
        </b-form>
      </validation-observer>
      <div class="row justify-content-center" v-else>
        <div class="col-md-8 text-center">
          <h3>{{row.asc_title_id}}</h3>
          <div class="sect-heading__line"></div>
          <p>{{row.asc_content_id}}</p>
          <hr>
          <p>{{ row.bottom_desc_id }}</p>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'

export default {
  props: {
    modulePage: String,
  },
  data(){
    return {
      productCatOpen: false,
      row: {},
      showContent: null,
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    showProductUpdate(){
      this.productCatOpen = true
    },
    closeProductForm(){
      this.productCatOpen = false
    },
    doSubmit(){
      this.$refs.VFormMedia.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateCustomer', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.productCatOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/customer', {}, 'GET').then(res => {
        this.showContent = res.data.showContent
        this.row = res.data.row
      })
    }
  }
}
</script>
