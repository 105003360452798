<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title title-tag="h5">Timeline</b-card-title>
        <b-button @click="addTimeline" class="btn-rounded" variant="info">Add Timeline <i class="fa fa-plus ml-2"></i></b-button>
      </div>
    </b-card-header>
    <b-card-body>
    <draggable tag="div" class="row draggable" v-model="timeline" @end="endDrag">
      <b-col md="4" class="slider-item c-pointer" v-for="(value, index) in timeline" :key="index">
        <div class="timeline__content">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h4><strong>{{value.mt_year}}</strong></h4>
              </div>
              <div class="wrap_content_tm" :style="`color: #fff; background-color: ${value.mt_background_color}`">
                <div class="form-row align-items-center">
                  <div class="col-md-auto col-4">
                    <div class="wrap_img_milestone">
                      <img :src="parent.uploader(value.mt_image)">
                    </div>
                  </div>
                  <div class="col-md col-8">
                    <b-badge v-if="value.mt_is_active == 'Y'" class="mb-3" variant="success">Active</b-badge>
                    <b-badge v-else class="mb-3" variant="danger">Inactive</b-badge>
                    <h5 class="m-0 font-weight-bold">{{ value.mt_title_id }}</h5>
                    <p>{{ truncate(value.mt_description_id, 60) }}</p>
                    <div>
                      <b-button
                        variant="danger"
                        size="sm"
                        pill
                        @click="$emit('delete', value, {type: 'deleteTimeline', idKey: 'mt_id', obj: 'Timeline'}, apiGet)"
                        class="btn-ico mr-2"
                        triggers="hover"
                        v-b-tooltip.hover="'Delete'"
                      >
                        <i class="fas fa-trash" />
                      </b-button>
                      <b-button
                        variant="warning"
                        size="sm"
                        pill
                        @click="updateTimeline(value)"
                        class="btn-icon"
                        triggers="hover"
                        v-b-tooltip.hover="'Update'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </draggable>

      <b-modal id="modalTimeline" size="xl" title="Form Timeline" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormTimeline"
        >
          <b-form @submit.prevent="handleSubmit(doSubmit)">
            <b-row>
              <b-col md=4>
                <b-form-group label-for="year">
                  <label>Year <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mt_year"></b-form-input>
                  <VValidate name="Year" v-model="row.mt_year" :rules="validation.mt_year" />
                </b-form-group>
                <div class="form-group">
                  <label class="control-label">Background Color <span class="text-danger mr5">*</span></label>
                  <verte :key="randInt" v-if="randInt" v-model="row.mt_background_color" model="hex" :enableAlpha="false"></verte>
                  <VValidate name="Background Color" v-model="row.mt_background_color" :rules="validation.mt_background_color" />
                </div>
                <div class="form-group">
                  <label class="control-label">Image <span class="text-danger">*</span></label>         
                  <Uploader v-model="row.mt_image" type="timeline"/>
                  <VValidate 
                    name="Image" 
                    v-model="row.mt_image"
                    :rules="validation.mt_image" 
                  />
                </div>
                <b-form-group class="mt-3">
                  <label>Status<span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="parent.Config.mr.StatusOptions"
                    v-model="row.mt_is_active"
                  />
                  <VValidate 
                    name="Status" 
                    v-model="row.mt_is_active" 
                    :rules="validation.mt_is_active" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=8>
                <b-row>
                  <b-col md=12>
                    <b-row>
                      <b-col md=4>
                        <div class="form-group mb-2">
                          <label>Title (ID) <strong class="text-danger">*</strong></label>
                          <b-form-input placeholder="..." v-model="row.mt_title_id"></b-form-input>
                          <VValidate 
                            name="Title (ID)" 
                            v-model="row.mt_title_id"
                            :rules="validation.mt_title_id" 
                          />
                        </div>
                      </b-col>
                      <b-col md=4>
                        <div class="form-group mb-2">
                          <label>Title (EN) <strong class="text-danger">*</strong></label>
                          <b-form-input placeholder="..." v-model="row.mt_title_en"></b-form-input>
                          <VValidate 
                            name="Title (EN)" 
                            v-model="row.mt_title_en"
                            :rules="validation.mt_title_en" 
                          />
                        </div>
                      </b-col>
                      <b-col md=4>
                        <div class="form-group mb-2">
                          <label>Title (JP) <strong class="text-danger">*</strong></label>
                          <b-form-input placeholder="..." v-model="row.mt_title_jp"></b-form-input>
                          <VValidate 
                            name="Title (JP)" 
                            v-model="row.mt_title_jp"
                            :rules="validation.mt_title_jp" 
                          />
                        </div>
                      </b-col>
                    </b-row>                                            
                  </b-col>
                  <b-col md=12>
                    <div class="form-group mb-2">
                      <label>Description (ID) <strong class="text-danger">*</strong></label>
                      <b-form-textarea :rows="3" v-model="row.mt_description_id"></b-form-textarea>
                      <VValidate 
                        name="Description (ID)" 
                        v-model="row.mt_description_id"
                        :rules="validation.mt_description_id" 
                      />
                    </div>
                    <div class="form-group mb-2">
                      <label>Description (EN) <strong class="text-danger">*</strong></label>
                      <b-form-textarea :rows="3" v-model="row.mt_description_en"></b-form-textarea>
                      <VValidate 
                        name="Description (EN)" 
                        v-model="row.mt_description_en"
                        :rules="validation.mt_description_en" 
                      />
                    </div>
                    <div class="form-group mb-2">
                      <label>Description (JP) <strong class="text-danger">*</strong></label>
                      <b-form-textarea :rows="3" v-model="row.mt_description_jp"></b-form-textarea>
                      <VValidate 
                        name="Description (JP)" 
                        v-model="row.mt_description_jp"
                        :rules="validation.mt_description_jp" 
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="doSubmit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
    </b-card-body>
    <b-card-footer>

    </b-card-footer>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
import draggable from 'vuedraggable'


let _ = global._

export default {
  components: {
    draggable
  },
  props: {
    modulePage: String,
  },
  data(){
    return {
      sliderData: [],
      configCKEditor: ['bold','italic','underline','strikethrough'],
      sliderCollapse: false,
      validation: {},
      timeline: [],
      row: {},
      randInt: 0
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
    this.$root.$on('bv::modal::hidden', () => {
      this.randInt = 0
    })
    this.$root.$on('bv::modal::shown', () => {
      this.randInt = 1
    })
  },
  methods: {
    endDrag(){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: 'sortTimeline',
            data: this.timeline
          }
        },
        "POST"
      ).then(() => {
        this.apiGet()
      })
    },
    truncate(str, length){
      return _.truncate(str, {length})
    },
    addTimeline(){
      this.row = {mt_is_active: 'Y', mt_background_color: '#39768E'}
      this.$bvModal.show('modalTimeline')
    },
    doSubmit(){
      this.$refs.VFormTimeline.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: this.row.mt_id ? 'updateTimeline' : 'addTimeline', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalTimeline')
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    updateTimeline(value){
      this.row = _.clone(value)
      this.$bvModal.show('modalTimeline')
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/timeline', {}, 'GET').then(res => {
        this.timeline = res.data.timeline
        this.validation = res.data.validation
      })
    }
  }
}
</script>
<style>
  .wrap_content_tm {
    box-shadow: 0in 0.052083333in 15px 0pt rgba(69,67,96,.1);
    position: relative;
    background-color: #eee;
    padding: 15px;
    border-radius: 60px;
  }
  .tm_col7 {
    background-color: #FBD504;
  }
  .wrap_img_milestone img {
    border-radius: 100%;
    position: relative;
  }
  
</style>
