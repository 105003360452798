<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title title-tag="h5">Certificates</b-card-title>
        <b-button @click="addCertificate" class="btn-rounded" variant="info">Add Certificate <i class="fa fa-plus ml-2"></i></b-button>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex align-items-center">              
        <template v-if="showContent">
          <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-certificate', apiGet)" id="certificate" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
        </template>
        <b-button 
          variant="secondary"
          class="btn-rounded ml-auto"
          v-if="formOpen"
          @click="closeRevenueForm">
          Close <i class="fas fa-times ml-2"></i>
        </b-button>
        <b-button
          variant="outline-info"
          class="btn-rounded ml-auto"
          @click="showRevenueUpdate"
          v-else
        >
          Update Content <i class="fas fa-pencil-alt ml-2"></i>
        </b-button>
      </div>

      <hr />

      <validation-observer
        v-slot="{ handleSubmit }"
        ref="VFormLeaderDesc"
        tag="div"
        v-if="formOpen"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitDesc)">
          <b-card class="border mb-0">
            <template #header>
              <h5 class="card-title">Content</h5>
            </template>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (ID) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_id"  />
                  <VValidate 
                    name="Title (ID)" 
                    v-model="dataModal.asc_title_id"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (EN) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_en"  />
                  <VValidate 
                    name="Title (EN)" 
                    v-model="dataModal.asc_title_en"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Title (JP) <span class="text-danger">*</span></label>         
                  <b-form-input v-model="dataModal.asc_title_jp"  />
                  <VValidate 
                    name="Title (JP)" 
                    v-model="dataModal.asc_title_jp"
                    rules="required|min:3|max:160" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (ID)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_id"  />
                  <VValidate 
                    name="Description (ID)" 
                    v-model="dataModal.asc_content_id"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (EN)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_en"  />
                  <VValidate 
                    name="Description (EN)" 
                    v-model="dataModal.asc_content_en"
                    rules="min:3" 
                  />
                </div>
              </b-col>
              <b-col md=4>
                <div class="form-group">
                  <label class="control-label">Description (JP)</label>         
                  <b-form-textarea :rows="3" v-model="dataModal.asc_content_jp"  />
                  <VValidate 
                    name="Description (JP)" 
                    v-model="dataModal.asc_content_jp"
                    rules="min:3" 
                  />
                </div>
              </b-col>
            </b-row>
            <template #footer>
              <div class="text-right">
                <b-button variant="secondary" class="btn-rounded mr-3" @click="closeRevenueForm">Cancel</b-button>
                <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
              </div>
            </template>
          </b-card>
        </b-form>
      </validation-observer>
      <div class="row justify-content-center" v-else>
        <div class="col-md-8 text-center">
          <h3>{{dataModal.asc_title_id}}</h3>
          <div class="sect-heading__line"></div>
          <p>{{dataModal.asc_content_id}}</p>
        </div>
      </div>

      <hr />

      <draggable v-if="!formOpen" tag="div" class="row draggable" v-model="certificate" @end="endDrag">
        <b-col md=3 v-for="(value, index) in certificate" :key="index">
          <div class="wrap_certificate c-pointer" >
            <div class="thumb_certificate">
              <img :src="parent.uploader(value.mc_image)" class="img-fluid">
            </div>
            <h3>{{value.mc_title_id}}</h3>
            <div class="bullet-cta-left">
              <b-badge v-if="value.mc_is_active == 'Y'" variant="success">Active</b-badge>
              <b-badge v-else variant="danger">Inactive</b-badge>
            </div>
            <div class="wrap_certificate_action">
              <b-button
                variant="danger"
                size="sm"
                pill
                @click="$emit('delete', value, {type: 'deleteCertificate', idKey: 'mc_id', obj: 'Certificate'}, apiGet)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Delete'"
              >
                <i class="fas fa-trash" />
              </b-button>
              <b-button
                variant="warning"
                size="sm"
                pill
                @click="updateCertificate(value)"
                class="btn-icon"
                triggers="hover"
                v-b-tooltip.hover="'Update'"
              >
                <i class="fas fa-pencil-alt" />
              </b-button>
            </div>
          </div>
        </b-col>
      </draggable>

      <b-modal id="modalCertificate" title="Form Certificate" no-close-on-backdrop>
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormCertificate"
        >
          <b-form @submit.prevent="handleSubmit(doSubmit)">
            <b-card-body>
              <b-row>
                <b-col md=12>
                  <div class="form-group mb-2">
                    <label>Title (ID) <strong class="text-danger">*</strong></label>
                    <b-form-input v-model="row.mc_title_id"></b-form-input>
                    <VValidate 
                      name="Title (ID)" 
                      v-model="row.mc_title_id"
                      :rules="validation.mc_title_id" 
                    />
                  </div>
                  <div class="form-group mb-2">
                    <label>Title (EN) <strong class="text-danger">*</strong></label>
                    <b-form-input v-model="row.mc_title_en"></b-form-input>
                    <VValidate 
                      name="Title (EN)" 
                      v-model="row.mc_title_en"
                      :rules="validation.mc_title_en" 
                    />
                  </div>
                  <div class="form-group mb-2">
                    <label>Title (JP) <strong class="text-danger">*</strong></label>
                    <b-form-input v-model="row.mc_title_jp"></b-form-input>
                    <VValidate 
                      name="Title (JP)" 
                      v-model="row.mc_title_jp"
                      :rules="validation.mc_title_jp" 
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Image <span class="text-danger">*</span></label>         
                    <Uploader v-model="row.mc_image" type="certificate"/>
                    <VValidate 
                      name="Image" 
                      v-model="row.mc_image"
                      :rules="validation.mc_image" 
                    />
                  </div>
                  <b-form-group class="mt-3">
                    <label>Status<span class="text-danger mr5">*</span></label>
                    <b-form-radio-group
                      :options="parent.Config.mr.StatusOptions"
                      v-model="row.mc_is_active"
                    />
                    <VValidate 
                      name="Status" 
                      v-model="row.mc_is_active" 
                      :rules="validation.mc_is_active" 
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </validation-observer>
        <template #modal-footer="{close}">
          <div class="text-right">
            <b-button class="btn-rounded" variant="outline-secondary" @click="close">Cancel</b-button>
            <b-button type="button" @click="doSubmit" class="btn-rounded ml-2" variant="primary">Save Changes</b-button>
          </div>
        </template>
      </b-modal>
    </b-card-body>
  </b-card>
</template>
<style scoped>
.wrap_certificate h3 {
  font-size: 18px;
      text-align: center;
      padding: 10px 0 0 0;
  }
</style>
<script>
import Gen from '@/libs/Gen.js'
import draggable from 'vuedraggable'


let _ = global._

export default {
  components: {
    draggable
  },
  props: {
    modulePage: String,
  },
  data(){
    return {
      formOpen: false,
      dataModal: {},
      validation: {},
      certificate: [],
      row: {},
      showContent: null,
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    showRevenueUpdate(){
      this.formOpen = true
    },
    closeRevenueForm(){
      this.formOpen = false
    },
    endDrag(){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: 'sortCertificate',
            data: this.certificate
          }
        },
        "POST"
      ).then(() => {
        this.apiGet()
      })
    },
    addCertificate(){
      this.row = {mc_is_active: 'Y'}
      this.$bvModal.show('modalCertificate')
    },
    doSubmit(){
      this.$refs.VFormCertificate.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: this.row.mc_id ? 'updateCertificate' : 'addCertificate', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalCertificate')
                this.apiGet()
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    updateCertificate(value){
      this.row = _.clone(value)
      this.$bvModal.show('modalCertificate')
    },
    doSubmitDesc(){
      this.$refs.VFormLeaderDesc.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateCertificateDesc', ...this.dataModal } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.formOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/certificate', {}, 'GET').then(res => {
        this.certificate = res.data.certificate
        this.showContent = res.data.showContent
        this.validation = res.data.validation
        this.dataModal = res.data.description
      })
    }
  }
}
</script>
