<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection v-if="moduleRole('hero')" :modulePage="modulePage" />
          <CompanyAtGlance v-if="moduleRole('company_glance')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)" />
          <TimelineSection v-if="moduleRole('timeline')" :modulePage="modulePage" @delete="doDelete(...arguments)" />
          <CorporateCulture v-if="moduleRole('corporate_culture')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)"  />
          <VisiMisiSection v-if="moduleRole('visi_misi')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)"  />
          <OurBusinessSection v-if="moduleRole('our_business')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)"  />
          <LeaderSection v-if="moduleRole('leader')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)" @delete="doDelete(...arguments)" />
          <RevenueSection v-if="moduleRole('revenue')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)" @delete="doDelete(...arguments)" />
          <CustomerSection v-if="moduleRole('customer')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)" />
          <CertificateSection v-if="moduleRole('certificate')" :modulePage="modulePage" @updateShowSection="updateShowSection(...arguments)" @delete="doDelete(...arguments)" />
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import SEOSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'
import CompanyAtGlance from './CompanyAtGlance.vue'
import TimelineSection from './TimelineSection.vue'
import CorporateCulture from './CorporateCulture.vue'
import VisiMisiSection from './VisiMisiSection.vue'
import OurBusinessSection from './OurBusinessSection.vue'
import LeaderSection from './LeaderSection.vue'
import RevenueSection from './RevenueSection.vue'
import CertificateSection from './CertificateSection.vue'
import CustomerSection from './CustomerSection.vue'


export default {
  name: 'BoAboutUs',
  extends: GlobalVue,
  components: {
    HeroSection,
    SEOSection,
    CompanyAtGlance,
    TimelineSection,
    CorporateCulture,
    VisiMisiSection,
    OurBusinessSection,
    LeaderSection,
    RevenueSection,
    CertificateSection,
    CustomerSection
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {     
    }
  },
  computed: {
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    updateShowSection(value, slug, callback){

      Gen.apiRest('/do/' + this.modulePage, {data: {type: 'updateShowSetting', value, slug}}).then(res => {
        if(res){
          this.$swal({
            title: res.data.message,
            icon: 'success',
          }).then(() => {
            callback()
          })
        }
      }).catch(err => {
        this.$swal({title: err.response.data.message, icon:'error'})
      })
    },
    doDelete(v, params = {}, callback){

      let id = v[params.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.swalLoading()

          Gen.apiRest("/do/"+this.modulePage,{data:{type:params.type,id:id}}, "POST")
            .then((resp = {})=>{
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              }).then(() => {
                callback()
              })
            })
            .catch(err=>{ 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })        
        }
      })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>