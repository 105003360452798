import { render, staticRenderFns } from "./CertificateSection.vue?vue&type=template&id=6ce1a12d&scoped=true&"
import script from "./CertificateSection.vue?vue&type=script&lang=js&"
export * from "./CertificateSection.vue?vue&type=script&lang=js&"
import style0 from "./CertificateSection.vue?vue&type=style&index=0&id=6ce1a12d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce1a12d",
  null
  
)

export default component.exports