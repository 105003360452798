<template>
  <b-card>
    <template #header>
      <h5 class="card-title">Company at Glance</h5>
    </template>
    <div class="d-flex align-items-center">              
      <template v-if="showContent">
        <b-form-checkbox @change="$emit('updateShowSection', $event, 'show-company-at-glance', apiGet)" id="company-brief" value="Y" unchecked-value="N" name="checkbox" v-model="showContent">Show Section?</b-form-checkbox>
      </template>
      <b-button 
        variant="secondary"
        class="btn-rounded ml-auto"
        v-if="companyBriefOpen"
        @click="closeFormCompany">
        Close <i class="fas fa-times ml-2"></i>
      </b-button>
      <b-button
        variant="outline-info"
        class="btn-rounded ml-auto"
        @click="showUpdateCompanyBrief"
        v-else
      >
        Update Content <i class="fas fa-pencil-alt ml-2"></i>
      </b-button>

    </div>
    <hr />

    
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="VFormGlance"
      tag="div"
      v-if="companyBriefOpen"
    >
      <b-form @submit.prevent="handleSubmit(doSubmit)">
        <b-card class="border mb-0">
          <template #header>
            <h5 class="card-title">Content</h5>
          </template>
          <b-row>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (ID) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_id"></b-form-input>
                <VValidate name="Title (ID)" v-model="row.asc_title_id" :rules="validation.asc_title_id" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (EN) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_en"></b-form-input>
                <VValidate name="Title (EN)" v-model="row.asc_title_en" :rules="validation.asc_title_en" />
              </b-form-group>
            </b-col>
            <b-col md=4>
              <b-form-group label-for="glance_title_id">
                <label>Title (JP) <span class="text-danger mr5">*</span></label>
                <b-form-input v-model="row.asc_title_jp"></b-form-input>
                <VValidate name="Title (JP)" v-model="row.asc_title_jp" :rules="validation.asc_title_jp" />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (ID) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_id"
                  ref="inputBriefCompanyID"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_id"

                />
                <VValidate 
                  name="Content (ID)" 
                  v-model="row.asc_content_id"
                  :rules="validation.asc_content_id" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (EN) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_en"
                  ref="inputBriefCompanyEN"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_en"

                />
                <VValidate 
                  name="Content (EN)" 
                  v-model="row.asc_content_en"
                  :rules="validation.asc_content_en" 
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-2">                      
                <label>Content (JP) <strong class="text-danger">*</strong></label>
                <CKEditor 
                  id="CompanyBrief_jp"
                  ref="inputBriefCompanyJP"
                  :ready-on-focus="true"
                  :customToolbar="configCKEditor"
                  :value.sync="row.asc_content_jp"

                />
                <VValidate 
                  name="Content (JP)" 
                  v-model="row.asc_content_jp"
                  :rules="validation.asc_content_jp" 
                />
              </div>
            </div>
          </div>
          <template #footer>
            <div class="text-right">
              <b-button variant="secondary" class="btn-rounded mr-3" @click="closeFormCompany">Cancel</b-button>
              <button type="submit" class="btn btn-info btn-rounded">Save Changes</button>
            </div>
          </template>
        </b-card>
      </b-form>
    </validation-observer>
    <div class="row" v-else>
      <div class="col-md-12">
        <h3>{{row.asc_title_id}}</h3>
        <div v-html="row.asc_content_id"></div>
      </div>
    </div>
  </b-card>
</template>
<script>
import Gen from '@/libs/Gen.js'
import CKEditor from '@/components/CKEditor'
// let _ = global._

export default {
  components: {
    CKEditor
  },
  props:{
    modulePage: String
  },
  data(){
    return {
      companyBriefOpen: false,
      row: {},
      configCKEditor: [ 'heading', '|', 'bold','italic','underline','strikethrough','link','bulletedList','numberedList','blockQuote'],
      validation: {},
      showCompanyBrief: false,
      showContent: null
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted(){
    this.apiGet()
  }, 
  methods: {
    showUpdateCompanyBrief(){
      this.companyBriefOpen = true
    },
    closeFormCompany(){
      this.companyBriefOpen = false
    },
    doSubmit(){
      this.$refs.VFormGlance.validate().then(success => {
        if(!success) return 

        this.parent.swalLoading()

        Gen.apiRest('/do/' + this.modulePage, 
            { data: { type: 'updateGlance', ...this.row } }
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                icon: 'success',
              }).then(() => {
                this.$bvModal.hide('modalHero')
                this.apiGet()
                this.companyBriefOpen = false
              })
            }
          }).catch(err => {
            this.$swal({title: err.response.data.message, icon:'error'})
          })
      })
    },
    apiGet(){
      Gen.apiRest('/get/' + this.modulePage +  '/company_at_glance', {}, 'GET').then(res => {
        this.row = res.data.row
        this.validation = res.data.validation
        this.showContent = res.data.showContent
      })
    }
  }
}
</script>
